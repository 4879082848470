export default [
  {
    path: 'content/list',
    name: 'ContentList',
    component: () => import('./List.vue'),
    meta: {
      name: '内容管理',
      isTag: true,
      keepAlive:true,
      icon: require('/src/assets/content.svg'),
      icons: require('/src/assets/content_show.svg')
    }
  },

  {
    path: 'content/addContent',
    name: 'ContentAdd',
    component: () => import('./Add.vue')
  },
  {
    path: 'content/editContent',
    name: 'ContentEdit',
    component: () => import('./Edit.vue')
  },
  {
    path: 'content/Detail',
    name: 'ContentDetail',
    component: () => import('./Detail.vue')
  },

  {
    path: '/',
    component: resolve => require(['./List.vue'], resolve)
  }
];
