export default [
  {
    path: 'invoice/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '发票管理',
      isTag: true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  // 发票申请列表
  {
    path: 'invoice/enterprise-invoice-list',
    component: resolve => require(['./enterprise-invoice-list'], resolve),
    meta: {
      name: '发票申请管理',
      isTag: true,
      keepAlive:true,
      icon: require('/src/assets/invoice02.svg'),
      icons: require('/src/assets/invoice02_show.svg')
    }
  },
  // 企业申请开票
  {
    path: 'invoice/enterprise-invoice-make',
    component: resolve => require(['./enterprise-invoice-make'], resolve)
  },
  // 平台发票列表
  // {
  //   path: "invoice/platform-invoice-list",
  //   component: resolve => require(["./platform-invoice-list"], resolve)
  // },
  // 申请发票详情
  {
    path: 'invoice/platform-invoice-info',
    component: resolve => require(['./platform-invoice-info'], resolve)
  },
  // 发票列表
  {
    path: 'invoice/invoice-List',
    component: resolve => require(['./invoice-list'], resolve),
    meta: {
      name: '发票管理',
      isTag: true,
      keepAlive:true,
      icon: require('/src/assets/invoice01.svg'),
      icons: require('/src/assets/invoice01_show.svg')
    }
  },
  // 发票信息列表
  {
    path: 'invoice/invoiceInfo-List',
    component: resolve => require(['./invoiceInfo-list'], resolve),
    meta: {
      name: '发票信息管理',
      isTag: true,
      keepAlive:true,
      icon: require('/src/assets/invoice03.svg'),
      icons: require('/src/assets/invoice03_show.svg')
    }
  }
];
