import Vue from 'vue';
import VueRouter from 'vue-router';
import { preload, getPreloadData } from '../lib/global';
import roleAccessRouter from './access/router.js';
import enterpriseRouter from './enterprise/router.js';
import userRouter from './user/router.js';
import contractRouter from './contract/router.js';
import indexRouter from './index/router.js';
import myabankRouter from './mybank/router.js';
import tradeRouter from './trade/router.js';
import taskRouter from './task/router.js';
import contentRouter from './content/router.js';
import recruitRouter from './recruit/router.js';
import invoiceRouter from './invoice/router.js';
import agentRouter from './agent/router.js';
import newsManagementRouter from './news/router.js';
import settingRouter from './setting/router.js';
import projectRouter from './project/router.js'
import payrollRouter from './payroll/router.js'
import customerRouter from './customer/router.js'
import enterpriseProjectRouter from './enterpriseProject/router.js'
import enterpriseCooperativeRouter from './enterpriseCooperative/router.js'
import follRouter from './followEnterprise/router.js';
import roleManagementRouter from './roleManagement/router.js';
import resourceManagementRouter from './resourceManagement/router.js';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Login',
    component: resolve => require(['./Login.vue'], resolve)
  },
  {
    path: '/login',
    name: 'Login',
    component: resolve => require(['./Login.vue'], resolve)
  },

  {
    path: '/register',
    name: 'Register',
    component: resolve => require(['../views/Register.vue'], resolve)
  },
  {
    path: '/forget',
    name: 'Forget',
    component: resolve => require(['../views/Forget.vue'], resolve)
  },
  {
    path: '/applyRoleAccess',
    name: 'applyRoleAccess',
    component: resolve => require(['../views/applyRoleAccess.vue'], resolve)
  },
  {
    path: '/system',
    name: 'System',
    component: resolve => require(['../views/System.vue'], resolve),
    children: [
      // {
      //   path: "setting/:name",
      //   component: resolve => require(["./setting/tab"], resolve)
      // },
      ...userRouter,
      ...enterpriseRouter,
      ...contractRouter,
      ...roleAccessRouter,
      ...indexRouter,
      ...myabankRouter,
      ...newsManagementRouter,
      ...enterpriseCooperativeRouter,
      ...tradeRouter,
      ...taskRouter,
      ...contentRouter,
      ...recruitRouter,
      ...invoiceRouter,
      ...roleManagementRouter,
      ...resourceManagementRouter,
      ...agentRouter,
      ...settingRouter,
      ...follRouter,
      ...projectRouter,
      ...payrollRouter,
      ...customerRouter,
      ...enterpriseProjectRouter
      // ...followEnterpriseRouter
    ]
  },
  {
    path: '/404',
    name: 'notFound',
    component: resolve => require(['./notFound.vue'], resolve)
  },
  {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const preloadPromise = preload
    .filter(it => it.reg && it.load)
    .filter(it => it.reg.test(to.fullPath))
    .map(it => {
      return it.load(to, from);
    });
  Promise.all(preloadPromise).then(result => {
    if (result.every(it => it)) {
      const data = getPreloadData();
      to.params.$preload = data;
      next();
    }
  });
});


export default router;
