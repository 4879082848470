export default [

  {
    path: 'enterpriseList/List',
    component: resolve => require(['./enterpriseList/List'], resolve),
    meta: {
      name: '用工企业',
      // isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/Cooperative01.png'),
      icons: require('/src/assets/cooperative01_show.png')
    }
   
  },
  {
    path: 'enterpriseArea/List',
    component: resolve => require(['./enterpriseArea/List'], resolve),
    meta: {
      name: '企业区域',
      // isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/Cooperative02.png'),
      icons: require('/src/assets/cooperative02_show.png')
    }
   
  },
  {
    path: 'enterpriseStores/List',
    component: resolve => require(['./enterpriseStores/List'], resolve),
    meta: {
      name: '企业门店',
      // isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/Cooperative03.png'),
      icons: require('/src/assets/cooperative03_show.png')
    }
   
  },
  {
    path: 'enterpriseContract/List',
    component: resolve => require(['./enterpriseContract/List'], resolve),
    meta: {
      name: '合同配置列表',
      // isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/Cooperative04.png'),
      icons: require('/src/assets/cooperative04_show.png')
    }
   
  },
  {
    path: 'enterpriseContract/Add',
    component: resolve => require(['./enterpriseContract/add'], resolve),
    meta: {
      name: '合同配置列表'
    }
  }
];
