export default [
  {
    path: 'resourceManagement/List',
    component: resolve => require(['./list'], resolve),
    meta: {
      keepAlive: true
    }
  },
  {
    path: 'resourceManagement/Add',
    component: resolve => require(['./addResource'], resolve)
  }
];
