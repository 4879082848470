export default [
  {
    // 登录用户信息
    path: 'RSBhVF7bvJm33r7QEpQEcoyyq7pES22IQ82P',
    component: resolve => require(['./RoleAccess.vue'], resolve)
  },
  // {
  //   path: "applyRoleAccess",
  //   name: "applyRoleAccess",
  //   component: resolve => require(["./applyRoleAccess.vue"], resolve)
  // },
  {
    path: 'setAccess',
    component: resolve => require(['./setAccess.vue'], resolve),
    meta: {
      name: '权限配置',
      isTag: true,
      keepAlive:true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    },
    children: [
      {
        path:'accessDetail',
        component: resolve => require(['./accessDetail.vue'], resolve)
      },
      {
        path: '/',
        component: resolve => require(['./accessDetail'], resolve)
      }
    ],
  },
  {
    path: 'addResource',
    component: resolve => require(['./addResource.vue'], resolve),
    meta: {
      name: '新增资源',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: '/',
    redirect: 'access'
  }
];
