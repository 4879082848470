/*
 * @Descripttion:
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-02-28 14:02:20
 * @LastEditors: 1821993421@qq.com 182993421@qq.com
 * @LastEditTime: 2023-04-19 09:58:54
 */
export default [
    {
        path: 'contract',
        component: (resolve) => require(['./index'], resolve),
        meta: {
            name: '合同管理',
            isTag: true,
            icon: require('/src/assets/contract.svg'),
            icons: require('/src/assets/contract_show.svg'),
        },
    },
    {
        path: 'contract/list',
        name: 'ContractList',
        component: (resolve) => require(['./List.vue'], resolve),
        meta: {
            name: '合同管理',
            isTag: true,
            // keepAlive:true,
            icon: require('/src/assets/contract01.svg'),
            icons: require('/src/assets/contract01_show.svg'),
        },
    },
    {
        path: 'contractUser/List',
        name: 'contractUserList',
        component: (resolve) => require(['./contractUser/index'], resolve),
        meta: {
            name: '合同签署人',
            isTag: true,
            // keepAlive:true,
            icon: require('/src/assets/contract03.png'),
            icons: require('/src/assets/contract03_show.png'),
        },
    },
    {
        path: 'contract/noSigned',
        component: (resolve) => require(['./noSigned'], resolve),
        meta: {
            name: '待签署合同',
            icon: require('/src/assets/contract04.png'),
            icons: require('/src/assets/contract04_show.png'),
        },
    },
    {
        path: 'contract/noReviewed',
        component: (resolve) => require(['./noReviewed'], resolve),
        meta: {
            name: '待审核合同',
            icon: require('/src/assets/contract05.png'),
            icons: require('/src/assets/contract05_show.png'),
        },
    },
    {
        path: 'contract/hasReviewed',
        component: (resolve) => require(['./hasReviewed'], resolve),
        meta: {
            name: '已审核合同',
            icon: require('/src/assets/contract06.png'),
            icons: require('/src/assets/contract06_show.png'),
        },
    },
    {
        path: 'contract/detail',
        name: 'ContractDetail',
        component: (resolve) => require(['./Detail.vue'], resolve),
    },
    {
        path: 'contract/add',
        name: 'ContractAdd',
        component: (resolve) => require(['./contract/Add.vue'], resolve),
    },
    {
        path: 'contract/contractTemplate',
        component: (resolve) =>
            require(['./template/contractTemplate'], resolve),
        meta: {
            keepAlive: false,
        },
    },
    {
        path: 'contract/pdf',
        component: (resolve) => require(['./pdf.vue'], resolve),
    },
    {
        path: 'contractTemplate/detail',
        name: 'ContractTemplateDetail',
        component: (resolve) => require(['./template/Detail.vue'], resolve),
    },
   {
    path: 'contract/contractMore',
    component: (resolve) => require(['./more/contractMore'], resolve),
    meta: {
        name: '批量合同',
        icon: require('/src/assets/contract07.png'),
        icons: require('/src/assets/contract07_show.png'),
    } 
  },
  {
    path: 'contract/contractMoreAdd',
    component: (resolve) => require(['./more/add'], resolve),
  
  },
  {
    path: 'contract/contractMorDetail',
    component: (resolve) => require(['./more/detail'], resolve),
  
    },
    {
        path: 'contractTemplate/list',
        name: 'ContractTemplateList',
        component: (resolve) => require(['./template/List.vue'], resolve),
        meta: {
            name: '合同模板',
            isTag: true,
            icon: require('/src/assets/contract02.svg'),
            icons: require('/src/assets/contract02_show.svg'),
        },
    },

    {
        path: '/',
        component: (resolve) =>
            require(['./template/contractTemplate'], resolve),
    },
]
